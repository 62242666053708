.header-menu {
	@apply z-50;
	@apply top-0;
	@apply bottom-0;
	@apply bg-black;
	@apply h-14;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	@apply md:overflow-hidden;

	@screen md {
		@apply h-20;
		@apply md:bg-transparent;
	}

	&.animate-on-scroll {
		@screen md {
			transition: background-color 0.3s;

			.logo-wrapper {
				.logo {
					@screen md {
						a {
							svg {
								path {
									fill: #fff;
								}
							}
						}
					}
				}
			}

			.menu-item {
				a {
					@apply text-white;

					@screen md {
						&::after {
							content: "";
							@apply bg-white;
						}
					}
				}
		
				&.active-tab {
					@screen md {
						a {
							&::after {
								@apply bg-white;
							}
						}
					}
				}
		
				&.black-point {
					@screen md {
						a {
							&::after {
								@apply bg-white;
							}
						}
					}
				}
		
				&.white-point {
					@screen md {
						a {
							&::after {
								@apply bg-white;
							}
						}
					}
				}
		
				&:hover {
					@screen md {
						a {
							&::after {
								@apply bg-white;
							}
						}
					}
				}
			}
			
		}
	}

	.logo-wrapper {
		@apply px-4;
		@apply md:px-0;
		@apply md:bg-transparent;

		.black-logo {
			@screen md {
				a {
					svg {
						path {
							fill: #000;
						}
					}
				}
			}
		}
	}

	&.open-menu {
		@apply h-full;
	}

	.menu-item {
		@apply relative;

		a {
			@apply flex;
			@apply flex-col;

			@screen md {
				&::after {
					content: "";
					@apply inline;
					@apply w-0;
					@apply bg-white;
					@apply h-px;
					@apply mt-0.5;
					@apply pb-1.5;
				}
			}
		}

		&.active-tab {
			@screen md {
				a {
					&::after {
						@apply pb-0;
						@apply h-1.5;
						@apply w-1.5;
						@apply rounded-full;
						@apply mx-auto;
					}
				}
			}
		}

		&.black-point {
			@screen md {
				a {
					&::after {
						@apply bg-black;
					}
				}
			}
		}

		&.white-point {
			@screen md {
				a {
					&::after {
						@apply bg-white;
					}
				}
			}
		}

		&:hover {
			@screen md {
				a {
					&::after {
						@apply h-1.5;
						@apply w-1.5;
						@apply rounded-full;
						@apply mx-auto;
					}
				}
			}
		}
	}

	.menu-icon {
		@apply cursor-pointer;
		@apply select-none;
		@apply px-2.5;
		@apply py-5;
		@apply bg-transparent;
		@apply border-0;
		font-size: 0;

		&:focus {
			@apply outline-none;
		}

		.navicon {
			@apply bg-transparent;
			@apply block;
			@apply h-0.5;
			@apply relative;
			transition: all 0.2s ease;
			@apply w-5;

			&::before,
			&::after {
				@apply bg-white;
				content: "";
				@apply block;
				@apply h-full;
				@apply absolute;
				transition: all 0.2s ease;
				@apply w-full;
			}

			&::before {
				@apply top-1;
			}

			&::after {
				@apply -top-1;
			}
		}

		&.close {
			.navicon {
				@apply bg-transparent;

				&::before {
					@apply transform;
					@apply -rotate-45;
				}

				&::after {
					@apply transform;
					@apply rotate-45;
				}
			}

			&:not(.steps) .navicon::before,
			&:not(.steps) .navicon::after {
				@apply top-0;
			}
		}
	}

	#menu {
		@apply text-white;

		&.open {
			@apply block;
			@apply pt-14;
			@apply flex-col;
			@apply w-full;
			@apply h-auto;
			@apply z-20;

			.menu-items {
				@apply flex-col;
				@apply h-auto;
			}
		}
	}
}
