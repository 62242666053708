.rich-text-section {
	.rich-text {
		p {
			@apply text-body1;
			@apply text-body1-dark;
			@apply pb-2;
			@apply font-museo-sans-300;
		}

		h2 {
			@apply text-h2-mobile;
			@apply text-heading-dark;
			@apply mb-2;
			@apply font-nexa-heavy;

			@screen md {
				@apply mb-4;
				@apply text-h2;
			}
		}

		h3 {
			@apply text-h3-mobile;
			@apply text-heading-dark;
			@apply mb-2;
			@apply font-museo-sans-700;

			@screen md {
				@apply text-h3;
			}
		}

		h4 {
			@apply text-body1;
			@apply text-heading-dark;
			@apply pb-4;
			@apply font-museo-sans-700;
		}

		a {
			@apply text-primary-500;
			@apply cursor-pointer;
		}
	}

	&.dark-rich-text {
		p {
			@apply text-body-light;
		}

		h2,
		h3,
		h4 {
			@apply text-heading-light;
		}
	}
}
